
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import API from "@/api/API";

export default Vue.extend({
  name: "SelectTemplateDialog",

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    serviceType: {
      type: Number,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    rules,
    dialog: false as boolean,
    model: "" as string,
    errorMessages: {} as any,
    loading: false as boolean,
    templates: [] as Array<SelectComponentInterface>
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.dialog = this.value;
      }
    }
  },

  async mounted(): Promise<void> {
    await this.loadData();
  },

  methods: {
    async toggle() {
      this.dialog = !this.dialog;
      await this.$emit("input", this.dialog);
    },
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        this.templates = await API.documentTemplates().getList(
          this.serviceType
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async setEditorsContent(): Promise<void> {
      this.$emit("setContent", this.model);
      await this.toggle();
    },
    async getTemplate(event: number): Promise<void> {
      try {
        const { content } = await this.$API
          .documentTemplates()
          .show(event, this.orderId);

        this.model = content;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    }
  }
});
